<template>
  <div class="signup">
    <!-- <h1>This is an login page</h1> -->
    <div class="topBar stickTop">
      <div class="logoIcon">
        <img src="images/logo-white.png" alt="" />
      </div>
      <div class="logoName">
        <span class="name1">Hotspex</span>
        <span class="name2">STUDIO</span>
      </div>
    </div>
    <div
      :class="{
        'h-centerContent': registerStatus === -1,
      }"
    >
      <section
        class="formContainer"
        :class="{
          posCenter: registerStatus !== -1,
        }"
      >
        <!-- form -->
        <div v-if="feedback === ''">
          <div class="formTop twocol">
            <h2>Register</h2>
            <router-link :to="{ name: 'login' }" class="linkBtn"
              >LOGIN</router-link
            >
          </div>
          <div class="formBody">
            <p class="lg-Text">
              Rapid + Robust Insight Solutions in your hands
            </p>
            <h2>SIGN UP NOW</h2>

            <div class="form">
              <!-- email -->
              <div class="field">
                <label class="lineLable">Email*</label>
                <div>
                  <input
                    type="email"
                    v-model="email"
                    required
                    class="fullWidth"
                    @blur="validateEmail"
                  />
                  <p class="formErrorMes" v-show="!email_valid">
                    Please input a valid email address
                  </p>
                </div>
              </div>
              <!-- fname -->
              <div class="field">
                <label class="lineLable">First name*</label>
                <div>
                  <input
                    type="text"
                    v-model="fstName"
                    required
                    class="fullWidth"
                    @blur="validateName('fstName')"
                  />
                  <p class="formErrorMes" v-show="!fstName_valid">
                    First name can not be empty
                  </p>
                </div>
              </div>
              <!-- lname -->
              <div class="field">
                <label class="lineLable">Last name*</label>
                <div>
                  <input
                    type="text"
                    v-model="lastName"
                    required
                    class="fullWidth"
                    @blur="validateName('lastName')"
                  />
                  <p class="formErrorMes" v-show="!lastName_valid">
                    Last name can not be empty
                  </p>
                </div>
              </div>
              <!-- company name -->
              <div class="field">
                <label class="lineLable">Company name*</label>
                <div>
                  <input
                    type="text"
                    v-model="cpName"
                    required
                    class="fullWidth"
                    @blur="validateName('company')"
                  />
                  <p class="formErrorMes" v-show="!cpName_valid">
                    Company name can not be empty
                  </p>
                </div>
              </div>
              <!-- job -->
              <div class="field">
                <label class="lineLable">Job title*</label>
                <div>
                  <input
                    type="text"
                    v-model="jobTitle"
                    required
                    class="fullWidth"
                    @blur="validateName('job')"
                  />
                  <p class="formErrorMes" v-show="!jobTitle_valid">
                    Job title can not be empty
                  </p>
                </div>
              </div>
              <!-- country -->
              <div class="field">
                <label class="lineLable">Country*</label>
                <select v-model="country">
                  <option value="" disabled>-- select from the list --</option>
                  <option
                    v-for="market in markets"
                    :key="market.MarketId"
                    :value="market.MarketName"
                  >
                    {{ market.MarketName }}
                  </option>
                </select>
                <p class="formErrorMes" v-show="!country_valid">
                  Please select the country from list.
                </p>
              </div>
              <!-- password -->
              <div class="field">
                <label class="lineLable">password*</label>
                <!-- password validation hint -->
                <div v-show="!pswdValidated" class="hintBox">
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': pswd.length >= 8 }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="pswd.length >= 8"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!(pswd.length >= 8)"
                    />
                    Longer than 8 characters
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_uppercase }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="has_uppercase"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!has_uppercase"
                    />
                    Has a capital letter
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_number }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="has_number"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!has_number"
                    />
                    Has a number
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_special }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="has_special"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!has_special"
                    />
                    Has a special character
                  </p>
                </div>
                <!-- first password -->
                <div class="iconInput">
                  <input
                    :type="pswdType"
                    v-model="pswd"
                    @input="passwordCheck"
                    required
                    class="fullWidth"
                  />
                  <span class="icon fixRight validated" v-show="pswdValidated"
                    ><font-awesome-icon icon="check"
                  /></span>
                </div>
                <div class="gap"></div>
                <!-- second password -->
                <div class="iconInput">
                  <input
                    :type="pswdType"
                    v-model="pswd2"
                    required
                    class="fullWidth"
                    :class="{ error: pswd !== pswd2 }"
                  />
                  <span
                    class="icon fixRight validated"
                    v-show="pswdValidated && pswd === pswd2"
                    ><font-awesome-icon icon="check"
                  /></span>
                </div>
                <div class="gap"></div>
                <!-- show password -->
                <div>
                  <label class="sm-text checkbox-label">
                    <input type="checkbox" v-model="pswdVisible" />
                    Show Password
                  </label>
                </div>
              </div>
              <!-- terms and condition -->
              <div class="field">
                <div class="gap"></div>
                <div>
                  <label class="sm-text checkbox-label">
                    <input type="checkbox" v-model="termAgree" />
                    I agree with
                    <a href="#" class="redLink"
                      >Hotspex STUDIO Terms and condistions</a
                    >*
                  </label>
                  <p class="formErrorMes" v-show="!term_valid">
                    You need to agree with the Hotspex STUDIO Terms and
                    condistions before proceed.
                  </p>
                </div>
                <div class="gap"></div>
                <div>
                  <button
                    class="button darkgreyBtn"
                    :disabled="formValidated"
                    @click.prevent="register"
                  >
                    CREATE ACCOUNT
                  </button>
                </div>
              </div>

              <div class="field text-center">
                Already have an account?
                <router-link :to="{ name: 'login' }" class="redLink"
                  >Login here</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- form feedback -->
        <div
          v-html="feedback"
          class="feedback"
          :class="{ expand: registerStatus !== -1 }"
        ></div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
// Hotspex-123
export default {
  data() {
    return {
      pswdVisible: false,
      pswdValidated: false,
      formValidated: false,
      termAgree: false,
      email: "",
      fstName: "",
      lastName: "",
      cpName: "",
      jobTitle: "",
      country: "",
      pswd: "",
      pswd2: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      email_valid: true,
      fstName_valid: true,
      lastName_valid: true,
      cpName_valid: true,
      jobTitle_valid: true,
      term_valid: true,
      country_valid: true,
      feedback: "",
      registerStatus: -1,
      markets: [],
    };
  },
  watch: {
    termAgree(newVal) {
      this.term_valid = newVal;
    },
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
  },
  methods: {
    passwordCheck() {
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      this.has_number = /\d/.test(this.pswd);
      this.has_uppercase = /[A-Z]/.test(this.pswd);
      this.has_special = format.test(this.pswd);

      if (
        this.pswd.length >= 8 &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.pswdValidated = true;
        console.log("pass validate");
      } else {
        this.pswdValidated = false;
      }
    },
    validateEmail() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email_valid = emailFormat.test(this.email);
    },
    validateName(field) {
      switch (field) {
        case "fstName":
          this.fstName_valid = this.fstName !== "";
          break;
        case "lastName":
          this.lastName_valid = this.lastName !== "";
          break;
        case "company":
          this.cpName_valid = this.cpName !== "";
          break;
        case "job":
          this.jobTitle_valid = this.jobTitle !== "";
          break;
        case "country":
          this.country_valid = this.country !== "";
          break;
        default:
          break;
      }
    },
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
    register() {
      this.formValidated = true;

      this.validateEmail();
      if (!this.email_valid) {
        this.formValidated = false;
      }

      if (!this.termAgree) {
        this.term_valid = false;
        this.formValidated = false;
        console.log("termValidated", this.formValidated);
      }

      this.validateName("fstName");
      this.validateName("lastName");
      this.validateName("company");
      this.validateName("job");
      this.validateName("country");

      if (
        !this.fstName_valid ||
        !this.lastName_valid ||
        !this.cpName_valid ||
        !this.jobTitle_valid ||
        !this.country_valid
      ) {
        this.formValidated = false;
        // console.log("nameValidated", this.formValidated);
      }

      if (!this.pswdValidated) {
        this.formValidated = false;
        // console.log("pswdValidated", this.formValidated);
      }

      if (!this.formValidated) return;

      console.log("pass the validation");

      const registerPromise = new Promise((resolve) => {
        wsUtils.RegisterUser(resolve, {
          email: this.email,
          firstName: this.fstName,
          lastName: this.lastName,
          company: this.cpName,
          jobTitle: this.jobTitle,
          country: this.country,
          password: this.pswd,
          planID: 1,
          // AccountID: 0,
          ClientIDs: "",
          UserTypeID: 0,
        });
      });
      registerPromise.then((data) => {
        this.showFeedback(data);
      });
    },
    register2() {
      this.showFeedback({ userID: 0, Status: 1 });
    },
    showFeedback(data) {
      console.log(data.userID, data.Status);

      if (data.userID !== 0) {
        console.log("show successful message");
        this.feedback =
          "<h2>Thank You</h2><p>Your request has been received and your account will be activated shortly.<p><p>Please check your inbox for further information.</p>";
        this.registerStatus = 1;
      } else {
        this.registerStatus = 0;
        if (data.Status === 1) {
          this.feedback =
            "<h2>Oops!</h2><p>It looks like that account may already exist. Please check your inbox for instructions on how to complete the registration process.</p><p>If you can't find the email in your Inbox, please check your Junk/SPAM folder or contact <a href='mailto:support@hotspexstudio.com' >support@hotspexstudio.com</a> for assistance.<p>";
        } else {
          this.feedback =
            "<p>Oops!It seems we have some problems here. Please contact to </p><p>Please contact <a href='mailto:support@hotspexstudio.com' >support@hotspexstudio.com</a> for assistance.</p>";
        }
      }
    },
  },
  created() {
    const marketPromise = new Promise((resolve) => {
      wsUtils.MarketList(resolve);
    });
    marketPromise.then((data) => {
      // console.log(data);
      this.markets = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.signup {
  background: url("../../public/images/logBG3.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  overflow-x: hidden;
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  padding: 20px;
  text-align: center;
  max-height: 0;
}

.feedback.expand {
  max-height: 90vh;
  opacity: 1;
}

.topBar {
  background-color: var(--hsred);
  height: 75px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 100;

  .logoIcon {
    background-color: var(--hsgrey);
    height: 100%;
    padding: 10px;

    img {
      max-height: 100%;
      display: block;
      margin: 0;
    }
  }

  .logoName {
    color: #fff;

    display: flex;
    flex-direction: column;
    padding-left: 10px;
    text-align: left;

    .name1 {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .name2 {
      font-size: 18px;
      letter-spacing: 3px;
      font-weight: 300;
    }
  }
}

.form {
  margin: 2em 0;
}

.h-centerContent {
  margin: 2em 0;
}

.field {
  margin-bottom: 2em;
  position: relative;

  .iconInput {
    position: relative;
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
  }

  input.fullWidth {
    width: 100%;
    padding: 0 5px;
    height: 32px;
  }

  .icon.fixRight {
    background-color: transparent;
    color: var(--iconBGGrey);
  }

  .validated {
    color: var(--succeedGreen) !important;
  }

  .iconBtn {
    cursor: pointer;
  }

  input {
    transition: background-color 0.5s;
  }

  input:focus {
    background-color: var(--lightBlue);
  }

  input.error {
    background-color: var(--lightRed) !important;
  }

  .hintBox {
    border: 1px solid var(--lineGrey);
    margin-bottom: 0.7em;
    padding-left: 8px;
    background-color: var(--lightGrey100);
  }

  select {
    width: 100%;
    height: 32px;
    padding: 0 5px;
    font-weight: 400;
    vertical-align: middle;
  }
}

.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: var(--hsred);
}
.frmValidation--passed .frmIcon {
  color: var(--succeedGreen);
}
</style>
